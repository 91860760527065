import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";
import Setup2 from "../../../../static/images/setup2.svg";
import { StaticImage } from "gatsby-plugin-image"

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
      <>
        <section className="onboarding-section">
          <div className="container mx-auto px-4">
            <div className="max-width1000">
              <Fragment>
                <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                  <AccordionHeader onClick={() => handleOpen(1)}>
                    How long will it take to get setup?
                  </AccordionHeader>
                  <AccordionBody>
                    Typical set up time is 4-7 business days. There are some
                    things that may delay setup time like if your home doesn't
                    currently have a coax connection or if a technition is
                    required. However, in any circumstance we strive to resolve
                    these situations quickly and get you connected ASAP
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                  <AccordionHeader onClick={() => handleOpen(2)}>
                    Do I need a coax/cable port to get installed
                  </AccordionHeader>
                  <AccordionBody>
                    You do, yes. Please take a look around your home for
                    anything that looks like the photos below. If you do not
                    have them, we'd be happy to get one installed for you.
                    Please text or call us as soon as possible to make this
                    change to your order!
                    <StaticImage
                      className="max-w-lg mx-auto"
                      src="../../../content/PurpleCowCoaxLine.png"
                      alt="A coax cable"
                      width={432}
                      height={382}
                    />
                    <div className="text-xl">Or</div>
                    <StaticImage
                      className="max-w-lg mx-auto"
                      layout="constrained"
                      src="../../../content/PurpleCowCoaxPort.png"
                      alt="A coax port in an outler"
                      width={432}
                      height={800}
                    />
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                  <AccordionHeader onClick={() => handleOpen(3)}>
                    When will I get my modem in the mail?
                  </AccordionHeader>
                  <AccordionBody>
                    Your modem should arrive the day you requested your internet
                    to start.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                  <AccordionHeader onClick={() => handleOpen(4)}>
                    What do I do when I get my modem in the mail?
                  </AccordionHeader>
                  <AccordionBody>
                    These instructions will be included with your modem. You can
                    also watch <a
                      href="https://www.youtube.com/watch?v=m8-2Lq1MlgU"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this helpful video
                    </a>.

                    <div className="max-w-full h-auto mx-auto" style={{ marginTop: "16px" }}>
                      <Setup2 className="w-full h-auto" />
                    </div>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                  <AccordionHeader onClick={() => handleOpen(5)}>
                    Should I schedule the cancelation of my other internet
                    provider now?
                  </AccordionHeader>
                  <AccordionBody>
                    Not yet. It is best to wait until you are fully hooked up
                    before canceling your current provider. This will ensure you
                    will not go without internet.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
                  <AccordionHeader onClick={() => handleOpen(6)}>
                    Can I keep my existing Phone Number?
                  </AccordionHeader>
                  <AccordionBody>
                    Absolutely! To ensure we can take it over from your current
                    provider, please do not cancel your service, we will do that
                    on your behalf when we take the number over. To complete the
                    takeover we do need a copy of your bill from your current
                    provider. It should be the most recent bill, and it should
                    show your name, your phone number and your account number.
                    You will recieve an email from us with instructions on how
                    to submit your bill.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>
          </div>
        </section>
      </>
    )
}

export default CommonQuestion
