import React from "react"

import LayoutNew from "../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import CommonOnboardingFAQs from "./new-pages/common/CommonOnboardingFAQs"

const SplittersPage = () => (
  <LayoutNew>
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <title>Onboarding faq – Purple Cow Internet 💜🐄</title>
    </Helmet>
    <div className="mx-auto prose prose-xl text-center">
      <br></br>
      <br></br>
      <h1>Onboarding FAQ's</h1>
      <p>
        Welcome to the Herd! Here are some Frequently Asked Questions we get from new customers:{" "}
      </p>
      <CommonOnboardingFAQs />
    </div>
  </LayoutNew>
)

export default SplittersPage
